<template>
    <div id='user-list'>
        <v-dialog v-model="table.dialog.open" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">审核拒绝</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field readonly v-model="table.dialog.item.nickname" label="当前账户昵称"
                                    dense></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field readonly v-model="table.dialog.item.real_name" label="当前账户真实姓名"
                                    dense></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field v-model="table.dialog.content.id_card" label="要绑定的账号的身份证号" dense
                                    hint="请输入要绑定的已存在的账号的身份证号"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text> <v-card-actions> <v-spacer></v-spacer> <v-btn color="error" outlined
                        @click="table.dialog.open = false"> 取消 </v-btn> <v-btn color="success" @click="bindPreUser()">
                        提交 </v-btn> </v-card-actions> </v-card>
        </v-dialog>
        <v-snackbar v-model="table.snak.open" multi-line :light="$vuetify.theme.dark" :timeout="2000">
            {{ table.snak.msg }}
            <template #action="{ attrs }">
                <v-btn color="error" text v-bind="attrs" @click="table.snak.open = false"> 关闭 </v-btn>
            </template>
        </v-snackbar>
        <v-card>
            <v-divider class='mt-4'></v-divider>
            <v-card-text class='d-flex align-center flex-wrap pb-4'>
                <v-text-field v-model='param.key' class='user-search me-3 mb-0' dense hide-details outlined placeholder='搜索'
                    clearable></v-text-field>
                <v-spacer></v-spacer>
            </v-card-text>
            <!-- table -->
            <v-data-table :headers='headers' :items='table.list' :loading='table.loading' :options.sync='param.options'
                :server-items-length='table.total'>
                <template #[`item.user_type`]='{ item }'>
                    <v-chip :color="item.user_type == 'P' ? 'primary' : 'success'">{{ enums.userTypes[item.user_type]
                    }}</v-chip>
                </template>
                <template #[`item.real_auth_state`]='{ item }'>
                    <v-chip :color="enums.authStatusColor[item.real_auth_state]" small>{{
                        enums.authStatus[item.real_auth_state]
                    }}</v-chip>
                </template>
                <template #[`item.avatar`]='{ item }'>
                    <v-avatar color="primary" size="32">
                        <img :src="item.avatar" alt="John">
                    </v-avatar>
                </template>
                <template #[`item.actions`]='{ item }'>
                    <v-btn @click="toBindPreUser(item)" v-if="item.user_type == 'D'" color="warning" small>换绑账号</v-btn>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>
<script>
// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiDeleteOutline, mdiDotsVertical, mdiExportVariant, mdiPencil, mdiPlus, mdiSquareEditOutline, } from '@mdi/js'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import wechatStoreModule from '../wechatStoreModule'
import useUserList from './useUserList'

export default {
    components: {}, setup() {
        const USER_APP_STORE_MODULE_NAME = 'app-user-wechat-user'
        // Register module
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, wechatStoreModule)
        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
        })
        const { headers, param, table, enums, toBindPreUser, bindPreUser, fetchUserList } = useUserList()

        return {
            headers,
            param,
            table,
            enums,
            toBindPreUser, bindPreUser,
            fetchUserList,
            // icons
            icons: { mdiSquareEditOutline, mdiPencil, mdiDotsVertical, mdiDeleteOutline, mdiPlus, mdiExportVariant, mdiAccountOutline, },
        }
    },
} </script>
<style lang='scss'> @import '@core/preset/preset/apps/user.scss';
</style>
