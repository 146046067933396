import store from '@/store'
import { mdiAccountCheckOutline, mdiAccountOutline, mdiAccountPlusOutline, mdiAccountRemoveOutline, mdiCogOutline, mdiDatabaseOutline, mdiDnsOutline, mdiPencilOutline } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

export default function useUserList() {
    const headers = [
        { text: '用户类型', value: 'user_type' },
        { text: '昵称', value: 'nickname' },
        { text: '姓名', value: 'real_name' },
        { text: '手机号', value: 'mobile' },
        { text: '头像', value: 'avatar' },
        { text: '实名状态', value: 'real_auth_state' },
        { text: '注册时间', value: 'create_at' },
        { text: '描述', value: 'desc' },
        { text: '操作', value: 'actions', align: 'center', sortable: false }
    ]
    const param = ref({
        key: '',
        options: { sortBy: ['id'], sortDesc: [true], page: 1, itemsPerPage: 10 }
    })
    const table = ref({
        list: [],
        total: 0,
        snak: { open: false, msg: '' },
        lodading: false,
        dialog: {
            open: false,
            loading: false,
            item: {},
            content: { id_card: "", id: '' }
        }
    })
    const enums = {
        userTypes: { "P": "乘客端", "D": "司机端" },
        authStatus: { "0": "未实名", "1": "已实名", "2": "未通过", "3": "待审核" },
        authStatusColor: { "0": "warning", "1": "success", "2": "error", "3": "info" },
    }
    const fetchUserList = () => {
        table.value.loading = true
        store.dispatch('app-user-wechat-user/fetchUserList', param.value).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
                table.value.snak = { open: true, msg: `错误码：${code}, ${msg}` }
                return
            }
            const { list, total } = res.data.data
            table.value.list = list || []
            table.value.total = total
        }).catch(error => { table.value.snak = { open: true, msg: `网络错误${error}` } }).finally(table.value.loading = false)
    }
    const toBindPreUser = (item) => {
        table.value.dialog = {
            open: true,
            loading: false,
            item: item,
            content: { id_card: "", id: item.id }
        }
    }
    const bindPreUser = () => {
        table.value.loading = true
        store.dispatch('app-user-wechat-user/bindPreUser', table.value.dialog.content).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
                table.value.snak = { open: true, msg: `错误码：${code}, ${msg}` }
                return
            }
            table.value.dialog.open = false
            fetchUserList()
        }).catch(error => { table.value.snak = { open: true, msg: `网络错误${error}` } }).finally(table.value.loading = false)
    }

    watch([param], () => {
        fetchUserList()
    }, { deep: true })
    return {
        headers,
        param,
        table,
        enums,
        toBindPreUser, bindPreUser,
        fetchUserList
    }
}
