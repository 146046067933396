import axios from '@axios'

export default {
    namespaced: true, state: {}, getters: {}, mutations: {}, actions: {
        fetchUserList(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios.get(`/admin-company/v1/app/user/${queryParams.options.page}/${queryParams.options.itemsPerPage}`, { params: queryParams }).then(response => resolve(response)).catch(error => reject(error))
            })
        },

        bindPreUser(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.post(`/admin-company/v1/app/user-driver/change-weixin`, data).then(response => resolve(response)).catch(error => reject(error))
            })
        },
    },
}
